import _ from "lodash";

async function getBody(response){
    if(response.status === 200){
        const json = await response.json();

        return json["body"];
    }
    else{
        return null;
    }
}

export async function fetchAnycastStatus(){
    let url = "http://localhost:7935/";
    const response = await fetch(url);

    return getBody(response);
}

export async function fetchVaultToken(userId, authToken){
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-User': userId,
        'X-Auth-Token': authToken
    };

    const options = {
        method: 'POST',
        headers: headers
    };

    let url = buildAPIURL("/theta/vault/token");
    const response = await fetch(url, options);

    return getBody(response);
}
