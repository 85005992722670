import "../css/styles.scss";
import _ from 'lodash';

const parse = require('url-parse');
import {fetchVaultToken} from "./api";

//Once Theta is a npm module, we can remove all this nastiness and just import the module...
const videojs = window.videojs;
global._babelPolyfill = false;
//End

let player = null;
let THETA = null;

let OVERLAY_ENABLED = false;

let CDN_BYTES = 0;
let TO_PEERS_BYTES = 0;
let FROM_PEERS_BYTES = 0;

let EDGE_PLAYER_BACKEND_RUNNING = null;

let STREAM_ID = null;

let hlsInstance = null;

let USE_THETA_PLUGIN = true;
let USE_VIDEO_JS = false;


const PLAYER_MODES = {
    VJS_HLSJS_THETA: 'VJS_HLSJS_THETA',
    VJS: 'VJS',
    HLSJS: 'HLSJS'
};
let PLAYER_MODE = PLAYER_MODES.VJS;

function parseToPrimitive(value) {
    try {
        return JSON.parse(value);
    }
    catch (e) {
        return value.toString();
    }
}

function getAuth() {
    try {
        let userId = window.localStorage.getItem("SLVR_USER_ID");
        let authToken = window.localStorage.getItem("SLVR_USER_AUTH");

        if (userId && authToken) {
            return {
                userId: userId,
                authToken: authToken
            }
        }
        else {
            return {};
        }
    }
    catch (e) {
        console.log("WARNING: localStorage is not available!");
        return {};
    }
}

async function getWalletAccessToken() {
    let auth = getAuth();

    if (_.isNil(auth.userId)) {
        return null;
    }

    let body = await fetchVaultToken(auth.userId, auth.authToken);

    return _.get(body, 'access_token', null);
}

function onThetaReady(theta) {
    if (theta) {
        //Event handlers
        theta.addEventListener(Theta.Events.PEER_ID_CHANGED, function (data) {
        });
        theta.addEventListener(Theta.Events.PEERS_CHANGED, function (data) {
        });
        theta.addEventListener(Theta.Events.TRAFFIC, function (data) {
            const type = data['type'];
            const size = data['stats']['size'];

            if(type === 'cdn'){
                CDN_BYTES = CDN_BYTES + size;
            }
            else if(type === "p2p_inbound"){
                FROM_PEERS_BYTES = FROM_PEERS_BYTES + size;
            }
            else if(type === "p2p_outbound"){
                TO_PEERS_BYTES = TO_PEERS_BYTES + size;
            }

            updateStatsDisplay();
        });
        theta.addEventListener(Theta.Events.PAYMENT_RECEIVED, function (data) {
        });
        theta.addEventListener(Theta.Events.PAYMENT_SENT, function (data) {
        });
        theta.addEventListener(Theta.Events.ACCOUNT_UPDATED, function (data) {
            window.TN_account = data;

            updateStatsDisplay();
        });
        theta.addEventListener(Theta.Events.SUBMITTED_RECEIVED_PAYMENTS, function (data) {
        });
        theta.connectWidget();

        THETA = theta;
    }
    else {
        console.log("onThetaReady(): Error: theta does not exist");
    }
}

function initPlayer(userId, videoId, sources, hlsOpts) {
    if(PLAYER_MODE === PLAYER_MODES.HLSJS){
        function handleMediaError(hls) {
            var now = performance.now();
            if(!recoverDecodingErrorDate || (now - recoverDecodingErrorDate) > 3000) {
                recoverDecodingErrorDate = performance.now();
                var msg = "trying to recover from media Error ..."
                console.warn(msg);
                hls.recoverMediaError();
            } else {
                if(!recoverSwapAudioCodecDate || (now - recoverSwapAudioCodecDate) > 3000) {
                    recoverSwapAudioCodecDate = performance.now();
                    var msg = "trying to swap Audio Codec and recover from media Error ..."
                    console.warn(msg);
                    hls.swapAudioCodec();
                    hls.recoverMediaError();
                } else {
                    var msg = "cannot recover, last media error recovery failed ..."
                    console.error(msg);
                }
            }
        }

        var video = document.getElementById('videojs-theta-plugin-player');
        var hls = new Hls({debug:true});
        hls.on(Hls.Events.ERROR, function(event,data) {
            var  msg = "Player error: " + data.type + " - " + data.details;
            console.error(msg);
            if(data.fatal) {
                switch(data.type) {
                    case Hls.ErrorTypes.MEDIA_ERROR:
                        handleMediaError(hls);
                        break;
                    case Hls.ErrorTypes.NETWORK_ERROR:
                        console.error("network error ...");
                        break;
                    default:
                        console.error("unrecoverable error");
                        hls.destroy();
                        break;
                }
            }
        });

        video.muted = true;
        var url = sources[0].src;
        var m3u8Url = decodeURIComponent(url);
        hls.loadSource(m3u8Url);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED,function() {
            video.play();
        });

        return null;
    }

    if(PLAYER_MODE === PLAYER_MODES.VJS){
        const player = window.player = videojs('videojs-theta-plugin-player', {
            muted: true,
            fluid: true,
            techOrder: ["html5"],
            sources: sources,
            controlBar: {
                children: [
                    'playToggle',
                    'volumePanel',
                    'spacer',
                    'qualitySelector',
                    'fullscreenToggle',
                ],
            },
        });
        player.autoplay('any');

        return player;
    }

    if(PLAYER_MODE === PLAYER_MODES.VJS_HLSJS_THETA){
        let bufferStalledErrorCount = 0;
        const player = window.player = videojs('videojs-theta-plugin-player', {
            muted: true,
            fluid: true,
            techOrder: ["theta_hlsjs", "html5"],
            sources: sources,
            theta_hlsjs: {
                videoId: videoId,
                userId: userId,
                walletUrl: "wss://api-wallet-service.thetatoken.org/theta/ws",
                onWalletAccessToken: getWalletAccessToken,
                onThetaReady: onThetaReady,
                onStreamReady: function(){
                    setTimeout(function(){
                        player.autoplay('any');
                    }, 500);
                },
                onHls(hls){
                    hls.on(Hls.Events.ERROR,function(event, data){
                        let errorType = data.type;
                        let isFatal = data.fatal;

                        if (isFatal) {
                            switch (errorType) {
                                case Hls.ErrorTypes.NETWORK_ERROR:
                                    // try to recover network error
                                    if(data.details === "manifestLoadError"){
                                        // Hard reload
                                        hls.destroy();
                                        hls.loadSource(this.url);
                                        hls.startLoad();
                                    }
                                    break;
                                case Hls.ErrorTypes.MEDIA_ERROR:
                                    // try to recover media error
                                    hls.recoverMediaError();
                                    break;
                                default:
                                    break;
                            }
                        }
                        else{
                            if(data.details === "bufferStalledError"){
                                console.log("bufferStalledErrorCount == " + bufferStalledErrorCount);
                                bufferStalledErrorCount++;
                                if(bufferStalledErrorCount >= 1){
                                    console.log("caught bufferStalledError ... RELOAD!");
                                    hls.destroy();
                                    // hls.loadSource(this.url);
                                    hls.startLoad();

                                    bufferStalledErrorCount = 0;
                                }
                            }
                            console.log("Not doing anything here..");
                        }
                    });
                },
                hlsOpts: hlsOpts
            },
            controlBar: {
                children: [
                    'playToggle',
                    'volumePanel',
                    'spacer',
                    'qualitySelector',
                    'fullscreenToggle',
                ],
            },
        });

        player.on("qualitySelected", function () {
            //Get the controlbar to show/hide as normal again
            player.userActive(true);
            player.reportUserActivity();

            //The controlbar gets confused when the menu is hidden when the user clicks a resolution, so let's do
            //what videojs does in their own code to reset the controlbar fading logic
            //https://github.com/videojs/video.js/pull/5692/commits/c8cc1f15584a5923264beb966b2b511a2241d6fe
            player.options_.inactivityTimeout = 0;
            player.options_.inactivityTimeout = 2000;
        });

        return player;
    }
}

function playStream(streamId){
    STREAM_ID = streamId;
    const url = parse(window.location.href, true);
    const timestamp = (new Date()).getTime();
    let sources = [
        {
            src: `http://127.0.0.1:7935/stream/${ streamId }.m3u8?ts=${ timestamp }`,
            type: "application/vnd.apple.mpegurl",
            label: "Auto"
        }
    ];

    let hlsOpts = _.chain(url.query)
        .pickBy(function (value, key) {
            return _.startsWith(key, "hls.");
        })
        .mapKeys(function(value, key) {
            return key.replace("hls.", "");
        })
        .mapValues(function (v) {
            return parseToPrimitive(v);
        })
        .value();

    //Decrease the latency
    hlsOpts = Object.assign({}, hlsOpts, {
        liveSyncDurationCount: 2,
        manifestLoadingTimeOut: 120000000,
        // manifestLoadingRetryDelay: 1000,
        // manifestLoadingMaxRetryTimeout: 5000,
        // manifestLoadingMaxRetry: 10,

        //These only help the cases of timeouts but doesn't retry on 404
        manifestLoadingMaxRetry: 10,
        manifestLoadingRetryDelay: 5000,
        manifestLoadingMaxRetryTimeout : 50000,
    });

    player = initPlayer(null, streamId, sources, hlsOpts);
}

async function init() {
    const url = parse(window.location.href, true);
    let streamId = url.query.streamId;
    let mode = url.query.mode;

    if(mode){
        PLAYER_MODE = mode;
    }

    if (streamId) {
        playStream(streamId);
        hideStreamIdSelectorOverlay();
    }
    else{
        showStreamIdSelectorOverlay();
    }

    setupOverlay();
    setupStreamIdSelectorOverlay();
    setupAnycastRequiredOverlay();
    startEdgePlayerPing();
}

function setupOverlay(){
    if(OVERLAY_ENABLED){
        const displayOverlayEl = document.getElementById("display-overlay");
        player.el().appendChild(displayOverlayEl);
    }
}

function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const WeiValue = new BigNumber("1000000000000000000");
BigNumber.set({ DECIMAL_PLACES: 2});

function updateStatsDisplay(){
    document.getElementById("traffic-stat-value-cdn").innerText = formatBytes(CDN_BYTES);
    document.getElementById("traffic-stat-value-from-peers").innerText = formatBytes(FROM_PEERS_BYTES);
    document.getElementById("traffic-stat-value-to-peers").innerText = formatBytes(TO_PEERS_BYTES);

    if(window.TN_account){
        const pendingTfuel = window.TN_account['account']['pending_tfuel'];
        const tfuelBalance = window.TN_account['account']['recv_account']['coins']['tfuelwei'];
        const pendingTfuelBN = new BigNumber(pendingTfuel);
        const tfuelBalanceBN = new BigNumber(tfuelBalance);
        const totalTfuelBN = tfuelBalanceBN.plus(pendingTfuelBN);
        const totalTfuel = totalTfuelBN.dividedBy(WeiValue).toFixed();

        document.getElementById("tfuel-balance-value").innerText = totalTfuel;
    }
}

function saveStreamId(){
    var inputEl = document.getElementById("streamId");
    var streamId = inputEl.value;

    hideStreamIdSelectorOverlay();

    //TODO change the URL here
    if (history.pushState) {
        var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?streamId=' + streamId;
        window.history.pushState({path:newurl},'',newurl);
    }

    playStream(streamId);
}

function setupStreamIdSelectorOverlay(){
    document.getElementById ("stream-selector-play-button").addEventListener ("click", saveStreamId, false);
}


function showStreamIdSelectorOverlay(){
    document.getElementById ("stream-selector-overlay").classList.remove("hide-me");
}

function hideStreamIdSelectorOverlay(){
    document.getElementById ("stream-selector-overlay").classList.add("hide-me");
}

function setupAnycastRequiredOverlay(){

}

function showEdgeNodeRequiredOverlay(){
    document.getElementById ("edge-node-required-overlay").classList.remove("hide-me");
}

function hideEdgeNodeRequiredOverlay(){
    document.getElementById ("edge-node-required-overlay").classList.add("hide-me");
}

let lostConnectionToEdgeNode = false;

async function pingEdgePlayer(){
    try {
        const timestamp = (new Date()).getTime();
        const addressResponse = await fetch(`http://127.0.0.1:7935?ts=${timestamp}`, {
            method: 'GET',
            mode: 'no-cors',
            headers: {
                accept: "text/plain"
            }
        });
        const address = await addressResponse.text();

        //Address is empty but we can assume it was successful?

        if(lostConnectionToEdgeNode === true){
            //Restart the stream
            if(hlsInstance){
                hlsInstance.recoverMediaError();
            }

            lostConnectionToEdgeNode = false;
        }

        hideEdgeNodeRequiredOverlay();
        EDGE_PLAYER_BACKEND_RUNNING = true;

        return address;
    }
    catch (e) {
        console.log(e);
        if(EDGE_PLAYER_BACKEND_RUNNING === true){
            lostConnectionToEdgeNode = true;
        }

        EDGE_PLAYER_BACKEND_RUNNING = false;
        showEdgeNodeRequiredOverlay();

        return null;
    }
}

function startEdgePlayerPing(){
    pingEdgePlayer();
    setInterval(pingEdgePlayer, 5000);
}

window.getOS = function() {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'macos';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'macos';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'windows';
    } else if (/Android/.test(userAgent)) {
        os = 'macos';
    } else if (!os && /Linux/.test(platform)) {
        os = 'macos';
    }

    return os;
};

window.launchEdgeNode = function(){
    // <!-- Deep link URL for existing users with app already installed on their device -->
    window.location = 'theta-edge-node://edgecast' + document.location.search;
};

window.downloadEdgeNodeForOs = function(){
    var targetOs = window.getOS();
    var downloadUrl = "https://api.thetatoken.org/downloads/edge-node/" + targetOs;
    window.open(downloadUrl);
};

init();
